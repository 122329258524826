import React from "react";
import styles from "./section.module.scss";
import useWidth from "../../hooks/useWidth";

export default function SectionCard({ img }) {
  const width = useWidth();
  const breakpoint = 1000;

  return (
    <div
      id={img.id}
      className={styles.cardBox}
      style={
        width < breakpoint
          ? {
              flexDirection: "column",
              width: "100%",
              marginBottom: "2em",
              height: "auto",
            }
          : null
      }
    >
      <div className={styles.titleBox}>
        <h3>{img.title} </h3>
      </div>
      <img className={styles.sectionCardImg} src={img.src} alt={"img"} />
      <div className={styles.sectionCard}>
        <p> {img.desc}</p>
        {img.link && (
          <a target="_blank" href={img.link}>
            ver mas{" "}
          </a>
        )}
      </div>
    </div>
  );
}
