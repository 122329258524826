import React from "react";
import styles from "./about.module.scss";
import whoWeAre from "../../assets/img/whoWeAre.jpg";
import useWidth from "../../hooks/useWidth";

export default function About(props) {
  const width = useWidth();

  const breakpoint = 800;
  return (
    <div
      className={styles.container}
      style={
        width < breakpoint
          ? { flexDirection: "column" }
          : { flexDirection: "row" }
      }
    >
      <div
        className={styles.inner}
        style={
          width < breakpoint
            ? { flexDirection: "column" }
            : { flexDirection: "row" }
        }
      >
        <img
          src={whoWeAre}
          style={
            width < breakpoint ? { width: "100%" } : { marginRight: "1em" }
          }
          alt="about"
        />
        <div
          style={{
            textAlign: "justify",
            alignSelf: "center",
            padding: "1em",
            flex: 1,
          }}
        >
          <h1 style={width < breakpoint ? { textAlign: "center" } : null}>
            Nosotros
          </h1>
          <p>
            En Donss, nuestra principal prioridad es brindar soluciones
            tecnológicas efectivas que ayuden a nuestros clientes a mejorar sus
            procesos y aumentar su productividad. Desde hace más de 6 años, nos
            hemos especializado en ofrecer servicios IT de calidad para
            negocios, empresas y pymes. Nos enorgullece poder decir que hemos
            ayudado a cientos de empresas a alcanzar sus objetivos y mejorar sus
            resultados.
          </p>
          <p>
            Nos dedicamos a eliminar las fallas que puedan surgir en la
            operatoria de su empresa, por lo que nuestra oferta de servicios es
            amplia y abarca todo tipo de necesidades tecnológicas. Desde el
            desarrollo de software a medida hasta el mantenimiento y soporte
            técnico, estamos aquí para ayudarte. Además, trabajamos con
            tecnología de última generación para garantizar que tus procesos
            sean óptimos y eficientes.
          </p>
          <p>
            En Donss, entendemos que cada empresa es única y tiene necesidades
            específicas. Por eso, nos adaptamos a cada cliente y ofrecemos
            soluciones personalizadas que se ajustan a tus requerimientos y
            presupuesto. Con nuestro equipo altamente capacitado y comprometido,
            siempre estamos listos para ayudarte a mejorar tu operación y
            alcanzar tus metas
          </p>
        </div>
      </div>
    </div>
  );
}
