import styles from "../header.module.scss";
import Link from "./Link";

const NavBar = ({ setScrollTo }) => {
  return (
    <div className={styles.navBarContainer}>
      {" "}
      <Link onClick={() => setScrollTo("servicios")} label={"Servicios"} />
      <Link onClick={() => setScrollTo("casos")} label={"Casos de éxito"} />
      <Link onClick={() => setScrollTo("about")} label={"Nosotros"} />
    </div>
  );
};

export default NavBar;
