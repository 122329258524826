export const CasesText1 = () => {
  return (
    <>
      <p>
        <b> Donss IT Solutions </b> ayudó a EMMSA a unificar y actualizar sus
        bases de datos SQL, reducir costos de mantenimiento y administración,
        mejorar la eficiencia de sus sistemas y garantizar la seguridad de la
        información.
      </p>
    </>
  );
};

export const CasesText2 = () => {
  return (
    <>
      <p>
        <b> Donss IT Solutions </b> fue capaz de solucionar rápidamente una
        crisis en el Hospital General de Agudos Dr. Cosme Argerich, recuperando
        la información crítica de la base de datos de Dermatología, y
        proporcionando una solución de respaldo confiable.
      </p>
    </>
  );
};

export const CasesText3 = () => {
  return (
    <>
      <p>
        <b> Donss IT Solutions </b> ofrece servicios de migración de bases de
        datos personalizados, como el realizado para Zanella Motolike, que
        mejoró su eficiencia y crecimiento de ventas. ¡Haz clic en la imagen y
        conoce más!
      </p>
    </>
  );
};
