import React from "react";
import styles from "./section.module.scss";
import SectionCard from "./SectionCard";
import BigImgCard from "./BigImgCard";
import Fade from "react-reveal/Fade";
import useWidth from "../../hooks/useWidth";

export default function Section(props) {
  //componente reutilizable para las distintas secciones.

  const { title, imgArray, bigImg, bigImgDesc } = props;
  const width = useWidth();
  const breakpoint = 1000;

  const SectionCardsArray = () => {
    // formatea el array de  data que le pasamos por props
    return (
      <div
        style={
          width < breakpoint
            ? {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                padding: "1em",
              }
            : null
        }
        className={styles.cardContainer}
      >
        {imgArray.map((img) => {
          return <SectionCard img={img} />;
        })}
      </div>
    );
  };

  return (
    <div className={!bigImg ? styles.container : styles.bigContainer}>
      <h2
        className={styles.title}
        style={width < breakpoint ? { textAlign: "center" } : null}
      >
        {" "}
        {title}
      </h2>
      <div className={styles.cardContainer}>
        <div>
          {!bigImg ? (
            <SectionCardsArray />
          ) : (
            <BigImgCard bigImgDesc={bigImgDesc} bigImg={bigImg} />
          )}
        </div>
      </div>{" "}
    </div>
  );
}
