import React from "react";
import styles from "./section.module.scss";
import useWidth from "../../hooks/useWidth";

export default function BigImgCard(props) {
  const width = useWidth();
  const breakpoint = 700;
  const { bigImg, bigImgDesc } = props;
  return (
    <div
      id="db"
      className={styles.BigImgCardContainer}
      style={width < breakpoint ? { width: "100%" } : null}
    >
      <img src={bigImg} alt={"img"} />

      {bigImgDesc}
    </div>
  );
}
