import React from "react";
import styles from "./footer.module.scss";
import whatsUpLogo from "../../assets/img/whatsappLogo.png";
export default function WhatssUp() {
  return (
    <div className={styles.whatssUpBtn}>
      <a href="https://wa.me/+5491173600576?text=Hola,%20quisiera%20mas%20información%20sobre%20sus%20servicios">
        <img
          style={{ height: 30, width: "auto" }}
          src={whatsUpLogo}
          alt={"whatssUpLogo"}
        />
      </a>
    </div>
  );
}
