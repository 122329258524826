import styles from "../header.module.scss";

const Link = (props) => {
  const { label, onClick } = props;
  return (
    <div onClick={onClick}>
      <span className={styles.link}> {label} </span>
    </div>
  );
};

export default Link;
