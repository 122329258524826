import { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/header/Header";
import CustomCarousel from "./components/carousel/Carousel";
import Section from "./components/section/Section";
import About from "./components/about/About";
import Footer from "./components/footer/Footer";
import farm1 from "./assets/img/SERV-FARM1.jpg";
import farm2 from "./assets/img/SERV-FARM2.jpg";
import farm3 from "./assets/img/SERV-FARM3.jpg";
import argerich from "./assets/img/argerich.jpg";
import emmsa from "./assets/img/emmsa.jpg";
import motolike from "./assets/img/motolike.jpg";

import dbImg from "./assets/img/SERV-BASE-DE-DATOS.jpg";
import {
  PharmacyText1,
  PharmacyText2,
  PharmacyText3,
} from "./components/pharmacysTexts";
import { CasesText1, CasesText2, CasesText3 } from "./components/CasesText";

//Array de contenidos de casos y farmacias
const farmaciasArray = [
  {
    src: farm1,
    title: "Optimización del rendimiento:",
    desc: <PharmacyText1 />,
    id: "optRend",
  },

  {
    src: farm2,
    title: "Migración de plataformas:",
    desc: <PharmacyText2 />,
    id: "migPla",
  },
  {
    src: farm3,
    title: "Monitorización y soporte: ",
    desc: <PharmacyText3 />,
    id: "monSop",
  },
];

const casesArray = [
  {
    src: emmsa,
    title: "CASO DE ÉXITO EMMSA",
    desc: <CasesText1 />,
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7059149548614475776",
  },

  {
    src: argerich,
    title: "CASO DE ÉXITO ARGERICH",
    desc: <CasesText2 />,
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7057709760057331714",
  },
  {
    src: motolike,
    title: "CASO DE ÉXITO MOTOLIKE",
    desc: <CasesText3 />,
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7056674441304449026",
  },
];

const BaseDeDatosDesc = () => {
  return (
    <div style={{ textAlign: "justify" }}>
      <h3> ¡Una base sólida para el éxito de su empresa!</h3>
      <p>
        Un diseño y arquitectura adecuados de la base de datos son fundamentales
        para garantizar que su empresa funcione de manera óptima. Una base de
        datos bien diseñada puede mejorar el rendimiento, reducir el tiempo de
        inactividad y mejorar la eficiencia, lo que se traduce en una mayor
        productividad y un mejor servicio al cliente.
      </p>
      <p>
        En Donss comprendemos que su empresa depende de una base de datos
        confiable y eficiente para garantizar su correcto funcionamiento. Es por
        eso que ofrecemos servicios de diseño y arquitectura de bases de datos
        personalizadas que se adaptan perfectamente a sus necesidades
        específicas. Nuestro equipo de expertos en bases de datos trabajará de
        cerca con usted para crear una solución sólida y confiable que respalde
        el éxito de su negocio.
      </p>
      <p>
        Confíe en Donss para garantizar el éxito de su negocio. ¡Contáctenos hoy
        mismo!
      </p>
    </div>
  );
};

function App() {
  //state to controll scroll on click
  const [scrollTo, setScrollTo] = useState("");

  useEffect(() => {
    const element = document.getElementById(scrollTo);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setScrollTo("");
    }
  }, [scrollTo]);

  return (
    <div style={{ marginTop: 5 }}>
      <div>
        <Header setScrollTo={setScrollTo} />

        <CustomCarousel setScrollTo={setScrollTo} />
        <div id="servicios">
          <Section
            id="servicios"
            title={"Servicios para Farmacias"}
            imgArray={farmaciasArray}
          />
        </div>
        <Section
          id="db"
          title={"Diseño y arquitectura de base de datos"}
          bigImgDesc={<BaseDeDatosDesc />}
          bigImg={dbImg}
        />
        <div id="casos">
          <Section
            id="casos"
            title={
              "Descubre como las pequeñas y grandes empresas se transforman digitalmente con Donss"
            }
            imgArray={casesArray}
          />
        </div>

        <div id="about">
          <About id="About" />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
