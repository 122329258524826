import { useState, useEffect } from "react";

// hook to read the width of the screen
const useWidth = (initialState = false) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return width;
};

export default useWidth;
