import React from "react";
import styles from "./footer.module.scss";
import Contact from "./Contact";
import WhatssUp from "./whatssUp";
import useWidth from "../../hooks/useWidth";
import IconLink from "../IconLink/IconLink";
import gmailLOGO from "../../assets/img/gmail.png";
import linkedinLogo from "../../assets/img/linkedin.png";
import facebookLogo from "../../assets/img/facebook.png";
import instagramLogo from "../../assets/img/instagram.png";
import logo from "../../assets/img/donsLogoVerde.png";

export default function Footer() {
  const width = useWidth();
  const breakpoint = 800;

  return (
    <>
      <div
        className={styles.container}
        style={width < breakpoint ? { margin: 0 } : null}
      >
        <div className={styles.logoBox}>
          <img
            src={logo}
            style={{ height: "auto", width: "10em" }}
            alt={"logo donss"}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={gmailLOGO} style={{ height: 15, width: "auto" }} />
            <p style={{ color: "white", fontSize: "0.7em", marginLeft: "5px" }}>
              info@donss.com.ar
            </p>
          </div>
          <p style={{ color: "white", fontSize: "0.7em", marginTop: "-5px" }}>
            {" "}
            +54 9 11 73600576
          </p>
          <div style={{ display: "flex" }}>
            <IconLink
              link={"https://www.facebook.com/DonssIT"}
              icon={facebookLogo}
            />
            <IconLink
              link={"https://www.linkedin.com/company/donss-it-solutions/"}
              icon={linkedinLogo}
            />
            <IconLink
              link={"https://www.instagram.com/donss_itsolutions/"}
              icon={instagramLogo}
            />
          </div>
        </div>{" "}
        <div className={styles.mobileContainer}>
          <WhatssUp />
        </div>{" "}
      </div>
    </>
  );
}
