import React from "react";
import { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import "./styles.css";

const SideBar = ({ setScrollTo }) => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(true);

  const handleScroll = (where) => {
    setScrollTo(where);
    setOpen({ menuOpen: false });
  };
  return (
    <Menu isOpen={open} onStateChange={() => setShow(!show)} right>
      <a
        style={{ marginBottom: "0.8em" }}
        onClick={() => handleScroll("servicios")}
      >
        Servicios
      </a>
      <a
        style={{ marginBottom: "0.8em" }}
        onClick={() => handleScroll("casos")}
      >
        Casos de éxito
      </a>
      <a onClick={() => handleScroll("about")}>Nosotros</a>
    </Menu>
  );
};

export default SideBar;
