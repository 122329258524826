import React from "react";
import styles from "./IconLink.module.scss";

export default function IconLink(props) {
  const { icon, link } = props;
  return (
    <div className={styles.logo}>
      <a href={link} target="_blank">
        <img
          style={{ height: 28, width: "auto", marginRight: 16 }}
          src={icon}
          alt={"whatssUpLogo"}
        />
      </a>
    </div>
  );
}
