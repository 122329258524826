import styles from "./carousel.module.scss";
import carousel1 from "../../assets/img/carrousel-web-donss1.jpg";
import carousel2 from "../../assets/img/carrousel-web-donss2.jpg";
import carousel4 from "../../assets/img/carrousel-web-donss4.jpg";
import carousel3 from "../../assets/img/carrousel-web-donss3.jpg";
import caruvert from "../../assets/img/caruvert.png";
import caruvert2 from "../../assets/img/caruvert2.png";
import caruvert3 from "../../assets/img/caruvert3.png";
import caruvert4 from "../../assets/img/caruvert4.png";

import useWidth from "../../hooks/useWidth";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function CustomCarousel({ setScrollTo }) {
  const width = useWidth();

  const breakpoint = 550;
  const breakImg = width < breakpoint ? styles.imgVert : styles.img;

  //component to render carImg
  const CarImg = ({ vertPic, pic, text }) => {
    return (
      <>
        <img
          className={width < breakpoint ? styles.imgVert : styles.img}
          src={width < breakpoint ? vertPic : pic}
          alt={"carousel"}
        />
        <h3 className={styles.legend}>{text}</h3>
      </>
    );
  };

  return (
    <div>
      <Carousel
        stopOnHover={false}
        infiniteLoop={true}
        autoPlay={true}
        showIndicators={true}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        interval={3000}
        swipeable={false}
      >
        <div
          className={styles.CarouselImg}
          onClick={() => setScrollTo("optRend")}
        >
          <CarImg
            vertPic={caruvert}
            pic={carousel2}
            text={`Optimización de rendimiento: desbloqueando el potencial completo de
        sus bases de datos`}
          />
        </div>
        <div className={styles.CarouselImg} onClick={() => setScrollTo("db")}>
          <CarImg
            vertPic={caruvert2}
            pic={carousel1}
            text={`Diseño y arquitectura de datos: creando soluciones de base de datos
              integrales`}
          />
        </div>
        <div
          className={styles.CarouselImg}
          onClick={() => setScrollTo("migPla")}
        >
          <CarImg
            vertPic={caruvert3}
            pic={carousel3}
            text={`Migración de plataforma sin problemas: asegurando una transición
        fluida y una disrupción mínima`}
          />
        </div>
        <div
          className={styles.CarouselImg}
          onClick={() => setScrollTo("monSop")}
        >
          {" "}
          <CarImg
            vertPic={caruvert4}
            pic={carousel4}
            text={`Consolidación de base de datos y del servidor: agilizando
        operaciones para la máxima eficiencia`}
          />
        </div>
      </Carousel>
    </div>
  );
}
