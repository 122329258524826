export const PharmacyText1 = () => {
  return (
    <div style={{ textAlign: "justify" }}>
      <p>
        Una base de datos ineficiente puede disminuir la productividad de su
        empresa.
      </p>
      <p>
        Permita que nuestros expertos en bases de datos optimicen su sistema,
        mejorando el rendimiento y la velocidad de sus aplicaciones.
      </p>
    </div>
  );
};

export const PharmacyText2 = () => {
  return (
    <div style={{ textAlign: "justify" }}>
      <p>
        ¿Está cansado de su antigua plataforma de base de datos que no se ajusta
        a las necesidades de su negocio?
      </p>
      <p>
        Permítanos ayudarlo a migrar su base de datos a una nueva plataforma más
        eficiente y escalable que le permita trabajar de manera más inteligente.
      </p>
    </div>
  );
};

export const PharmacyText3 = () => {
  return (
    <div style={{ textAlign: "justify" }}>
      <p>
        Es importante asegurarse de que su base de datos esté funcionando
        correctamente en todo momento.
      </p>
      <p>
        Permítanos ayudarlo a monitorear y mantener su sistema, asegurándose de
        que esté siempre en óptimas condiciones.
      </p>
    </div>
  );
};
