import { useState, useEffect } from "react";
import styles from "./header.module.scss";
import NavBar from "./navBar/NavBar";
import logo from "../../assets/img/donssLogo.png";
import SideBar from "./navBar/sideBar/SideBar";
import useWidth from "../../hooks/useWidth";

const Header = ({ setScrollTo }) => {
  const width = useWidth();

  const breakpoint = 800;
  return (
    <>
      <div className={styles.container}>
        {width >= breakpoint ? (
          <div className={styles.logoContainer}>
            <img className={styles.logo} src={logo} alt={"logo donss"} />{" "}
          </div>
        ) : (
          <img className={styles.mobileLogo} src={logo} alt={"logo donss"} />
        )}
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {width >= breakpoint && <NavBar setScrollTo={setScrollTo} />}
        </div>{" "}
        <div
          style={{
            width: "25%",
          }}
        ></div>
      </div>
      {width < breakpoint && <SideBar setScrollTo={setScrollTo} />}
    </>
  );
};

export default Header;
